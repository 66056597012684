import { Visibility } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FormattedDate from '../../components/formatted-date';
import { CompactTableCell } from '../../components/globals';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import LogEntityLink from '../../components/log-entity-link';
import { useTitle } from '../../hooks';
import { routes, useLogDetailsApi } from '../../lib';
import { Log } from '../../model';

const StyledGrid = styled(Grid)`
  flex-grow: 1;
`;

const LogDataValue: FunctionComponent<{ value: any }> = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((v, i) => [
          i > 0 && ', ',
          <LogDataValue value={v} key={i} />,
        ])}
      </>
    );
  }
  if (value === null) {
    return <em>null</em>;
  }
  if (typeof value === 'boolean') {
    return <em>{value ? 'true' : 'false'}</em>;
  }
  if (typeof value === 'string') {
    return <>{value}</>;
  }
  return <>{value}</>;
};

const LogsDetails: FC = () => {
  const { logId } = useParams<{ logId: string | undefined }>();
  const { isLoading, error, data } = useLogDetailsApi(logId as unknown as number);
  const [log, setLog] = useState<Log>();
  useTitle(`Log ${logId} details`);

  useEffect(() => {
    setLog(data?.find((log) => logId && log.id === +logId));
  }, [data, logId]);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.logs}>
            Back to logs
          </Button>
        }
      />
    );
  }

  if (isLoading || !log) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: 'Home', link: routes.dashboard },
    { label: 'Logs', link: routes.logs },
  ];

  return (
    <Container maxWidth="lg">
      <Header title={`Log #${log.id}`} breadcrumbs={detailBreadcrumbs} />
      <Grid container spacing={3} direction="row">
        <StyledGrid item>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    display="block"
                  >
                    Log
                  </Typography>
                  <Typography variant="body1" display="inline">
                    <LogEntityLink
                      entityClass={log.objectClass}
                      entityId={log.objectId}
                      label={log.label}
                    />{' '}
                  </Typography>
                  <Typography display="inline" color="textSecondary">
                    {log.action}d{' '}
                    <strong>
                      <FormattedDate date={log.loggedAt} />
                    </strong>{' '}
                    {log.username && (
                      <>
                        by <strong>{log.username}</strong>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="overline" color="textSecondary">
                    Version
                  </Typography>
                  <Typography variant="body1">{log.version}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {log.data &&
                    Object.entries(log.data).map(([field, value]) => (
                      <TableRow key={field}>
                        <CompactTableCell variant="head">
                          {field}
                        </CompactTableCell>
                        <TableCell>
                          <LogDataValue value={value} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </StyledGrid>
        <Grid item>
          <Card>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <CompactTableCell>Action</CompactTableCell>
                    <TableCell>Logged At</TableCell>
                    <TableCell>Username</TableCell>
                    <CompactTableCell>Version</CompactTableCell>
                    <CompactTableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data
                      .sort((a, b) => b.version - a.version)
                      .map((row) => (
                        <TableRow key={row.id} selected={row.id === log.id}>
                          <CompactTableCell>{row.action}</CompactTableCell>
                          <TableCell>
                            <FormattedDate date={row.loggedAt} />
                          </TableCell>
                          <TableCell>{row.username}</TableCell>
                          <CompactTableCell>{row.version}</CompactTableCell>
                          <CompactTableCell>
                            <Link to={routes.log(row.id)}>
                              <IconButton aria-label="View" size="small">
                                <Visibility />
                              </IconButton>
                            </Link>
                          </CompactTableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LogsDetails;
