import { Blameable, Commentable, Nullable, Timestampable } from './index';
import { MailingList } from './mailing-list';
import { Signature } from './signature';
import { ID } from './types';

export interface Attachment {
  id: ID;
  file: string;
  name: string;
  size: number;
  dimensions?: {
    width: number;
    height: number;
  }
}

interface MailingDeliveryStats {
  total: number;
  success: number;
  error: number;
  recipients?: Array<{
    recipient: string;
    status: 'success' | 'error';
  }>;
}

export interface MailingDelivery extends Blameable, Timestampable {
  id: ID;
  mailing: ID;
  stats: MailingDeliveryStats;
  test: boolean;
  key: string;
}

export enum MailingStatus {
  Draft = 'draft',
  Sent = 'sent',
}

export interface Mailing extends Commentable, Blameable, Timestampable {
  id: ID;
  name: string;
  subject: string;
  mailingLists: MailingList[];
  signature: Signature;
  content: string;
  attachments: Attachment[];
  deliveries: MailingDelivery[];
  status: MailingStatus;
}

export interface MailingFilters {
  search: string;
  mailingList: string;
}

export interface MailingFormValues
  extends Omit<
    Mailing,
    | 'id'
    | 'deliveries'
    | 'status'
    | 'signature'
    | 'mailingLists'
    | 'attachments'
    | keyof Blameable
    | keyof Timestampable
  > {
  signature: Nullable<ID>;
  mailingLists: ID[];
  attachments: Array<{
    attachmentId?: number;
    name: string;
    size: number;
    file?: File;
  }>;
}

export const getMailingFormValues = (mailing?: Mailing): MailingFormValues => ({
  name: mailing?.name || '',
  subject: mailing?.subject || '',
  mailingLists: mailing?.mailingLists.map((m) => m.id) || [],
  signature: mailing?.signature.id || null,
  content: mailing?.content || '',
  attachments:
    mailing?.attachments.map((attachment) => ({
      attachmentId: attachment.id,
      name: attachment.name,
      size: attachment.size,
    })) || [],
  comment: mailing?.comment || '',
});
