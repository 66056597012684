import {
  Autocomplete,
  Box,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {Editor} from '@tinymce/tinymce-react';
import React, {useEffect, useRef, useState, VoidFunctionComponent,} from 'react';
import {Control, Controller, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import 'tinymce';
import {Editor as TinyMCEEditor} from 'tinymce';
import 'tinymce-i18n/langs/de';
import 'tinymce/icons/default';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/template';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/paste';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/themes/silver';
import {useUnlimitedPaginationApi} from '../../hooks';
import {apiRoutes} from '../../lib';
import {Dict, MailingFormValues, MailingList, Nullable, Signature,} from '../../model';
import Error from '../error';
import {StyledCardTitle} from '../globals';
import MultiUpload from '../multi-upload';

export const MailingFormFields: VoidFunctionComponent<{
  control: Control<MailingFormValues>;
}> = ({control}) => {
  const {i18n, t} = useTranslation();
  const editorRef = useRef<Nullable<TinyMCEEditor>>(null);
  const [signature, setSignature] = useState<Nullable<Signature>>(null);

  const {
    data: signatures,
    isLoading: signaturesLoading,
    error: signaturesError,
  } = useUnlimitedPaginationApi<Dict, Signature>(apiRoutes.signatures);
  const {
    data: mailingLists,
    isLoading: mailingListsLoading,
    error: mailingListsError,
  } = useUnlimitedPaginationApi<Dict, MailingList>(apiRoutes.mailingLists);

  const selectedSignature = useWatch({control, name: 'signature'});
  useEffect(() => {
    if (!signatures) {
      return;
    }
    setSignature(signatures.find((s) => s.id === selectedSignature) || null);
  }, [selectedSignature, signatures]);

  if (signaturesError || mailingListsError) {
    return (
      <CardContent>
        <Error title={'Error'}/>
      </CardContent>
    );
  }

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Mailing')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name="name"
              render={({field, fieldState}) => (
                <TextField
                  label={t('Name')}
                  fullWidth
                  {...field}
                  required
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name="mailingLists"
              render={({field, fieldState}) => (
                <Autocomplete
                  id="mailingLists"
                  multiple={true}
                  loading={mailingListsLoading}
                  options={mailingLists.filter((ml) => ml.enabled)}
                  getOptionLabel={(option) => option.name}
                  noOptionsText={t('Keine Verteiler verfügbar')}
                  value={
                    mailingLists.filter((ml) => field.value.includes(ml.id)) ||
                    []
                  }
                  onChange={(event, value) =>
                    field.onChange(value.map((ml) => ml.id))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                      label={t('Empfänger (Verteiler)')}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        {...getTagProps({index})}
                        label={option.name}
                        key={index}
                      />
                    ));
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name="subject"
              render={({field, fieldState}) => (
                <TextField
                  label={t('Betreff')}
                  fullWidth
                  {...field}
                  required
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name="comment"
              render={({field, fieldState}) => (
                <TextField
                  label={t('Interner Kommentar')}
                  fullWidth
                  multiline={true}
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Controller
              control={control}
              name="content"
              render={({field, fieldState}) => (
                <>
                  <Editor
                    onInit={(e, editor) => (editorRef.current = editor)}
                    onEditorChange={(v) => field.onChange(v)}
                    value={field.value}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: ['lists template wordcount preview paste'],
                      branding: false,
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | template | preview',
                      content_style:
                        'body { font-family: "Roboto","Helvetica","Arial",sans-serif; font-size: 1rem; }',
                      templates: [
                        {
                          title: t('Vorname'),
                          description: t('Vorname des Empfängers'),
                          content: '[vorname]',
                        },
                        {
                          title: t('Nachname'),
                          description: t('Nachname des Empfängers'),
                          content: '[nachname]',
                        },
                        {
                          title: t('E-Mail'),
                          description: t('E-Mail des Empfängers'),
                          content: '[email]',
                        },
                        {
                          title: t('Anzeigename'),
                          description: t('Anzeigename des Empfängers'),
                          content: '[anzeigename]',
                        },
                        {
                          title: t('Jahr'),
                          description: t('Aktuelles Jahr'),
                          content: '[jahr]',
                        },
                      ],
                      language: i18n.language,
                      paste_as_text: true
                    }}
                  />
                  {fieldState.error && (
                    <FormHelperText error={true}>
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name="signature"
              render={({field, fieldState}) => (
                <FormControl variant="standard" fullWidth required={true}>
                  <InputLabel id="signature">{t('Signatur')}</InputLabel>
                  {signaturesLoading ? (
                    <CircularProgress/>
                  ) : (
                    <Select
                      labelId="signature"
                      fullWidth
                      required={true}
                      value={field.value || ''}
                      label={t('Signatur')}
                      onChange={(e) => {
                        setSignature(
                          signatures.find((s) => s.id === e.target.value) ||
                          null
                        );
                        field.onChange(e.target.value);
                      }}
                      disabled={signaturesLoading}
                      error={fieldState.isTouched && fieldState.invalid}
                    >
                      {signatures.map((signature, index) => (
                        <MenuItem key={index} value={signature.id}>
                          {signature.name} <Typography variant="caption" style={{fontSize: 'small'}} color="textSecondary">({signature.email})</Typography>
                          <Typography variant="caption" style={{fontSize: 'small'}} color="textPrimary">{' '}{signature.comment}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )}
            />
            {signature && (
              <Box mt={3}>
                <Typography variant="overline" color="textSecondary">
                  {t('Signatur Vorschau')}
                </Typography>
                <Paper variant="outlined" sx={{padding: 1}}>
                  <div dangerouslySetInnerHTML={{__html: signature.html}}/>
                </Paper>
              </Box>
            )}
          </Grid>
          <Grid item md={6}>
            <MultiUpload control={control}/>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
